<template>
  <div>
    <b-card
      :title="detail.name"
      img-src="https://picsum.photos/600/300/?image=25"
      img-alt="Image"
      img-top
      tag="article"
      style="max-width: 20rem"
      class="mb-2"
    >
      <b-card-text>
        {{ detail.description }}
      </b-card-text>

      <router-link to="/masters/roles" class="btn btn-primary"
        >Kembali</router-link
      >
      <button @click="deleteClass(detail.id)" class="btn btn-danger ml-2">Hapus</button>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    setDetailRoles(data) {
      this.detail = data;
    },
    deleteClass(id) {
      Swal.fire({
        title: "Apakah kamu yakin ingin menghapus data?",
        showCancelButton: true,
        confirmButtonText: `Delete`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          ApiService.post("api/roles/delete/" + id ).then(()=>{
            Swal.fire("Data berhasil dihapus", "", "success");
            this.$router.push({ path: "/masters/roles" });
          }).catch(() => {
            Swal.fire("Data gagal dihapus", "", "error");
          })
        } else {
          Swal.fire("Data batal dihapus", "", "info");
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "masters/settings" },
      { title: "Hapus Sekolah" },
    ]);

    ApiService.setHeader();
    ApiService.get("api/roles/" + this.$route.params.id)
      .then((response) => {
        this.setDetailRoles(response.data.data)
      })
      .catch(({ response }) => {
        //("responseerrorr", response);
      });
  },
};
</script>
